import * as Sentry from '@sentry/sveltekit'
import posthog from 'posthog-js'
import { browser } from '$app/environment'

import initApp from '$lib/init'
import { env } from '$env/dynamic/public'
import { PUBLIC_POSTHOG_PROJECT_ID, PUBLIC_SENTRY_DSN } from '$env/static/public'
import { currentBusiness, currentUser, appVersion } from './lib/stores'
import { getAppVersion, getUserDisplayName, checkIfNewVersion } from './lib/utils'
import { get } from 'svelte/store'

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 0, // only for error logging for now, no profiling needed
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [
		Sentry.replayIntegration({
			// TODO: remove once out of alpha/beta
			maskAllText: false,
			blockAllMedia: false
		})
	],
	environment: env.PUBLIC_ENVIRONMENT,
	enabled: env.PUBLIC_ENVIRONMENT !== 'development'
})

if (browser && env.PUBLIC_ENVIRONMENT !== 'development') {
	posthog.init(PUBLIC_POSTHOG_PROJECT_ID, {
		api_host: 'https://eu.i.posthog.com',
		person_profiles: 'identified_only'
	})
}

currentBusiness.subscribe((business) => {
	Sentry.setContext('business', { id: business.id, name: business.name })
})

currentUser.subscribe((user) => {
	if (user) {
		Sentry.setUser({ id: user.id, username: getUserDisplayName({ user }) })
		posthog.identify(user.id, { name: getUserDisplayName({ user }) })
	} else {
		Sentry.setUser(null)
		posthog.reset()
	}
})

initApp()

getAppVersion().then((version) => {
	appVersion.set(version)
})

document.addEventListener('visibilitychange', async function () {
	if (document.visibilityState === 'visible') {
		if (await checkIfNewVersion(get(appVersion))) {
			window.location.reload()
		}
	}
})

export const handleError = Sentry.handleErrorWithSentry()
